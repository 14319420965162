<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3>智慧研学综合实践一站式解决方案</h3>
        <!-- <p class="title_en">Smart Study Travel Management Solution</p> -->
      </div>
      <div class="solution_content">
        <p>
          方案通过“一平台三系统”为研学旅行、综合实践、劳动教育行业企事业单位提供信息化数字化整体解决方案。截止2022年4月，已成功建设并运行了国家级研学实践教育示范区湖北宜昌研学旅行与综合实践管理平台（已服务44万中小学生，55个营基地，24个研学旅行社）、四川省成都市研学综合实践管理平台（将服务180
          万中小学生，数百个营基地和研学旅行社）、四川省宜宾市学生自主探究研学实践系统、四川省德阳市研学管理平台等。
        </p>
        <img src="@/assets/images/solutionProduct/new_solution7.png" />
        <img src="@/assets/images/solutionProduct/solution7_item2_pc1.png" />
      </div>
    </div>
    <div class="campus_box">
      <div class="campus">
        <div class="title">
          <h3>学生研学综合实践管理平台</h3>
          <span>服务教育主管部门等主体</span>
          <p>
            学生研学综合实践管理平台以国家政策性文件为导向，以实践教育教学规律为依据，结合地方特点，“四位一体”联通教育主管部门、中小学校、教育服务机构（如研学机构、营基地）及学生家庭，构成区域研学综合实践评价管理与服务生态网。<br />
            用户通过系统端口上传研学旅行、综合实践、劳动教育等各类实践性活动的过程性写实记录，辅以各方对学生学习过程和结果的质性及量化评价，共同构成学生实践履历成长档案，评价结果及档案内容会同步进学生的综素评价档案，作为升学参考的重要依据及学生全面发展健康成长的指南。
          </p>
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/solution7_item2_pc2.png" />
        </div>
      </div>
      <div class="campus">
        <div class="title">
          <h3>学生自主探究研学实践系统</h3>
          <span>服务中小学生</span>
          <p>
            在疫情常态化和文教创新融合发展背景下，开拓当地区及全国各类型目的地资源（如博物馆、科技馆、植物园、动物园、自然景观、企业工厂、农村农庄、科研场所、大学院校、实践基地、历史文化遗产、红色教育基地、国防教育基地、演艺影视城、各类主题公园等）开发高品质OMO(线上线下融合)自主探究研学实践课程。学生/家长自主选择出行并完成OMO体验探究课程，完课可获得成就和相应实践学分。
          </p>
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/solution7_item2_pc3.png" />
        </div>
      </div>
      <div class="campus">
        <div class="title">
          <h3>智能营地管理系统</h3>
          <span>服务公立综合实践基地（营地）和大型私立营地</span>
          <p>
            帮助营基地打通软硬件信息孤岛，提升管理与操作效能，提升服务水平和客户满意度，可定制化开发满足多元化需求。
          </p>
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/solution7_item2_pc4.png" />
        </div>
      </div>
      <div class="campus">
        <div class="title">
          <h3>智慧研学活动管理系统</h3>
          <span>服务研学游学教育机构、旅行社</span>
          <p>
            帮助机构提升课程与服务水平，提升管理与操作执行效能，提升家长/学生留存与二次变现能力。
          </p>
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/solution7_item2_pc5.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto;

      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          display: block;
          margin: 40px auto;
          &:nth-child(2) {
            width: 100%;
          }
          &:nth-child(3) {
            width: 40%;
            margin-top: 40px;
          }
        }
      }
    }
    .campus_box {
      background: $--color-white;
      .campus {
        text-align: center;
        padding-top: 40px;

        img {
          // padding-bottom: 40px;
          display: block;
          margin: 0px auto 40px;
          width: 100%;
        }
        &:last-child {
          margin-bottom: 100px;
        }
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 40px;
      &_content {
        width: 90%;
        // width: 100%;
        max-width: 1320px;
        margin: 0 auto;
      }
      img {
        width: 100% !important;
        margin: 40px auto 0 !important;
      }
    }
  }
  .content .campus_box .campus {
    width: 90%;
    margin: 0 auto;
    max-width: 1320px;
  }
  .campus_box .campus .title h3 {
    margin-bottom: 30px;
  }
  .campus_box .campus .title span {
    font-size: 20px;
  }
  .campus_box .campus .title p {
    font-size: 18px;
    text-align: justify;
    color: #666;
    line-height: 35px;
    margin: 35px 0;
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        h3 {
          font-size: 24px;
          line-height: 24px;
        }
        p {
          font-size: 12px;
        }
      }
      .campus_box {
        .campus {
          .title {
            width: 90%;
            margin: 0 auto;
            h3 {
              font-size: 24px;
              line-height: 24px;
            }
            span {
              font-size: 16px;
            }
            p {
              font-size: 12px;
            }
          }
          &_content {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
          img {
            width: 90%;
          }
        }
      }
    }
  }
}
.view-mobile {
  .campus_box .campus .title h3 {
    margin-bottom: 15px;
  }
  .campus_box .campus .title span {
    line-height: 24px;
    font-size: 15px;
  }
  .campus_box .campus .title p {
    font-size: 14px;
    text-align: justify;
    color: #666;
    line-height: 28px;
    margin-bottom: 30px;
  }
  @extend %solution;
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      line-height: 30px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      // padding-top: 40px;
      p {
        font-size: 14px;
        line-height: 28px;
      }
      img {
        &:nth-child(2) {
          width: 100%;
        }
        &:nth-child(3) {
          width: 100%;
        }
      }
    }
    .campus_box {
      .campus {
        padding: 40px 15px 0;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 30px;
        }
        img {
          width: 100%;
          margin: 20px 0 0;
        }
      }
    }
  }
}
</style>
