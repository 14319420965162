<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3>课后服务解决方案</h3>
        <!-- <p class="title_en">Smart Study Travel Management Solution</p> -->
      </div>
      <div class="solution_content">
        <p>
          区校一体课后服务解决方案是为教育局、学校、教师、家长、校外机构量身打造课后服务的解决方案，提供丰富的课后服务运营、管理模式。以为孩子提供优质、丰富的课后托管服务为目标，通过对教育的深刻理解和创新的技术革新，灵活满足不同教育局、学校和机构客户的差异化需求以及快速创新的追求。系统充分利用云计算、大数据、人工智能等新技术，将信息化作为课后托管服务质量提升的内生变量，全面支撑国家对双减工作的总体要求，引领教育现代化发展，推动教育理念更新、模式变革、体系重构，促进教育优质均衡发展。
        </p>
        <img src="@/assets/images/solutionProduct/afterclass.jpg" />
      </div>
    </div>
    <div class="campus_box">
      <div class="campus">
        <div class="title">
          <h3>局端功能应用结构</h3>
          <!-- <p class="title_en">Intelligent Base Management System</p> -->
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/afterclass1.png" />
        </div>
      </div>
      <div class="campus pt0">
        <div class="title">
          <h3>校外机构端功能应用结构</h3>
          <!-- <p class="title_en">Intelligent Base Management System</p> -->
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/afterclass2.png" />
        </div>
      </div>
      <div class="campus pt0">
        <div class="title">
          <h3>学校&教师&家长端功能应用结构</h3>
          <!-- <p class="title_en">Courses And Activiti</p> -->
        </div>
        <div class="campus_content">
          <img
            class="last"
            src="@/assets/images/solutionProduct/afterclass3.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 40px;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto 30px;
      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          display: block;
          margin: 40px auto;
          &:nth-child(2) {
            width: 100%;
          }
          &:nth-child(3) {
            width: 40%;
            margin-top: 40px;
          }
        }
      }
    }
    .campus_box {
      background: $--color-white;
      .campus {
        text-align: center;
        padding-top: 40px;

        img {
          // padding-bottom: 40px;
          display: block;
          margin: 0px auto 40px;
          width: 100%;
        }
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 40px;
      &:last-child {
        margin-bottom: 40px;
      }
      .title {
        h3 {
          margin: 0 auto 40px;
        }
      }
      &_content {
        width: 90% !important;
        max-width: 1320px !important;
        margin: 0 auto;
      }
      .last {
        margin-bottom: 100px !important;
      }
      img {
        width: 100%;
        display: block;
        margin: 0px auto !important;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        p {
          font-size: 18px;
        }
      }
      .campus_box {
        .campus {
          img {
            width: 90%;
          }
        }
      }
    }
  }
}
.view-mobile {
  @extend %solution;
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      margin-bottom: 15px !important;
      line-height: 30px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      padding-top: 0 !important;
      p {
        font-size: 14px;
        line-height: 28px;
      }
      img {
        &:nth-child(2) {
          width: 100%;
        }
        &:nth-child(3) {
          width: 100%;
        }
      }
    }
    .campus_box {
      .pt0 {
        padding-top: 0 !important;
      }
      .campus {
        padding: 10px 15px 0;
        img {
          width: 100%;
          margin: 30px 0;
        }
      }
    }
  }
}
</style>
