import { render, staticRenderFns } from "./SafeCampus.vue?vue&type=template&id=208f45b1&scoped=true"
import script from "./SafeCampus.vue?vue&type=script&lang=js"
export * from "./SafeCampus.vue?vue&type=script&lang=js"
import style0 from "./SafeCampus.vue?vue&type=style&index=0&id=208f45b1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "208f45b1",
  null
  
)

export default component.exports