<template>
  <div class="solution-product">
    <!--  头部轮播  -->
    <div id="swiper">
      <!--      <swiper class="swiper" :options="swiperOption">-->
      <!--        <swiper-slide>-->
      <!--          <img-->
      <!--            src="@/assets/images/solutionProduct/banner_products_solution.png"-->
      <!--            alt="校端产品 一人一课表"-->
      <!--          />-->
      <!--        </swiper-slide>-->
      <!--        <swiper-slide v-for="(image, index) in swiperList" :key="index">-->
      <!--          <img :src="image.url" :alt="image.title" />-->
      <!--        </swiper-slide>-->

      <!--        <div class="swiper-pagination" slot="pagination"></div>-->
      <!--      </swiper>-->
      <swiper ref="swiper" class="swiper" :options="swiperOption">
        <swiper-slide v-for="(image, index) in navList" :key="index">
          <img :src="image.bannerBgUrl" :alt="image.title" />
          <div class="swiper_content">
            <div>
              <h1 v-html="image.title" :class="{ sp: image.sp }"></h1>
              <p>
                {{ image.bannerCon }}
              </p>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div
      ></swiper>
      <!-- <img :src="navList[activeNav].bannerBgUrl" alt="校端产品 一人一课表" />
      <div class="swiper_content">
        <h1 v-if="activeNav === 6">智慧研学</h1>
        <h1 v-else v-html="navList[activeNav].title"></h1>
        <p>
          {{ navList[activeNav].bannerCon }}
        </p>
      </div> -->
    </div>
    <!--  导航  -->
    <div class="navigation">
      <ul>
        <li
          v-for="(nav, index) in navList"
          :key="index"
          @click="toggleNav(index, nav)"
        >
          <span
            :class="{
              'active-nav': activeNav === index,
            }"
            >{{ nav.title }}</span
          >
        </li>
      </ul>
    </div>
    <component :is="navList[activeNav].component"></component>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import 'swiper/css/swiper.css'
import solution1_banner from '@/assets/images/solutionProduct/banner_products_solution1.jpg'
import solution2_banner from '@/assets/images/solutionProduct/banner_products_solution2.png'
import solution3_banner from '@/assets/images/solutionProduct/banner_products_solution3.jpg'
import solution4_banner from '@/assets/images/solutionProduct/banner_products_solution4.png'
import solution5_banner from '@/assets/images/solutionProduct/banner_products_solution5.png'
import solution6_banner from '@/assets/images/solutionProduct/banner_products_solution6.png'
import solution7_banner from '@/assets/images/solutionProduct/banner_products_solution7.png'
// import solution8_banner from '@/assets/images/solutionProduct/banner_products_solution8.png'
import solution9_banner from '@/assets/images/solutionProduct/banner_products_solution9.jpg'
import solution10_banner from '@/assets/images/solutionProduct/banner_products_solution10.jpg'
import solution11_banner from '@/assets/images/solutionProduct/banner_products_solution11.png'
import solution12_banner from '@/assets/images/solutionProduct/banner_products_solution12.jpg'
import solution13_banner from '@/assets/images/solutionProduct/banner_products_solution13.png'

import NewCollegeEntrance from './solutions/NewCollegeEntrance.vue'
import SchoolGroups from './solutions/SchoolGroups.vue'
import ElementarySchoolVersion from './solutions/ElementarySchoolVersion'
import SecondarySchoolVersion from './solutions/SecondarySchoolVersion'
import GreenCampus from './solutions/GreenCampus'
import SafeCampus from './solutions/SafeCampus'
import SmartStudy from './solutions/SmartStudy'
import XiaohuiAIOT from './solutions/XiaohuiAIOT'
import TeacherDevelopment from './solutions/TeacherDevelopment'
import AfterClassService from './solutions/AfterClassService'
import AcademicQuality from './solutions/AcademicQuality'
import Integrated from './solutions/Integrated'
import IntelligentPenClassroom from './solutions/IntelligentPenClassroom'
export default {
  name: 'Solution',
  components: {
    NewCollegeEntrance,
    SchoolGroups,
    ElementarySchoolVersion,
    SecondarySchoolVersion,
    GreenCampus,
    SafeCampus,
    SmartStudy,
    XiaohuiAIOT,
    TeacherDevelopment,
    AfterClassService,
    IntelligentPenClassroom,
    swiper,
    swiperSlide,
    AcademicQuality,
  },
  directives: {
    // swiper: directive,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const componentName = to.query.name
      vm.navList.map((item, index) => {
        if (item.componentName === componentName) {
          vm.activeNav = index
          vm.$nextTick(() => {
            vm.$refs.swiper.swiper.slideTo(vm.activeNav + 1, 500)
          })
        }
      })
    })
  },
  watch: {
    $route(newVal) {
      const componentName = newVal.query.name
      this.navList.map((item, index) => {
        if (item.componentName === componentName) {
          this.activeNav = index
          this.$refs.swiper.swiper.slideTo(this.activeNav + 1, 500)
          this.$store.commit('setPcHeaderIsTransparent', true)
        }
      })
    },
  },
  data() {
    return {
      navList: [
        {
          title: '新高考一站式解决方案',
          bannerBgUrl: solution1_banner,
          bannerCon:
            '针对新高考形式下的走班教学，以课表数据为基础，提供一站式的选、排、管、测、评、教解决方案',
          componentName: 'NewCollegeEntrance',
          component: NewCollegeEntrance,
        },
        {
          title: '集团校管理解决方案',
          bannerBgUrl: solution2_banner,
          bannerCon:
            '为集团校提供一站式服务，通过信息化手段实现自身业务流程的再造与优化',
          componentName: 'SchoolGroups',
          component: SchoolGroups,
        },
        {
          title: '“五育并举”评价整体解决方案（小学版）',
          bannerBgUrl: solution3_banner,
          bannerCon:
            '协助学校达成多元育人目标，打造“高品质、定制式”的“探究型”学校',
          componentName: 'ElementarySchoolVersion',
          component: ElementarySchoolVersion,
          sp: true,
        },
        {
          title: '“五育并举”评价整体解决方案（中学版）',
          bannerBgUrl: solution4_banner,
          bannerCon:
            '以数据为驱动、构建模型重构方法，实现学生各年级学习情况全过程纵向评价及德智体美劳全要素横向评价',
          componentName: 'SecondarySchoolVersion',
          component: SecondarySchoolVersion,
          sp: true,
        },
        {
          title: '绿色校园整体解决方案',
          bannerBgUrl: solution5_banner,
          bannerCon:
            '通过绿色智能产品和管控平台，实现设备、环境、平台、数据的互联互通，为师生创造更安全、更节能、更舒适的校园环境',
          componentName: 'GreenCampus',
          component: GreenCampus,
        },
        {
          title: '平安校园整体解决方案',
          bannerBgUrl: solution6_banner,
          bannerCon:
            '以安全前置预防性管理为核心，整体打造和提升校园安全风险防控体系和能力，全面赋能校园安全工作',
          componentName: 'SafeCampus',
          component: SafeCampus,
        },
        {
          title: '智慧研学综合实践一站式解决方案',
          bannerCon:
            '着眼于智慧研学、智能基（营）地，通过智慧研学管理平台，打造一个全新的研学生态体系',
          bannerBgUrl: solution7_banner,
          componentName: 'SmartStudy',
          component: SmartStudy,
        },
        {
          title: '家校社一体化劳动教育解决方案',
          bannerCon: '联通学校、劳动实践基地及家庭的三位一体的综合解决方案',
          bannerBgUrl: solution13_banner,
          componentName: 'Integrated',
          component: Integrated,
        },
        // {
        //   title: '晓慧XMUI AIoT平台解决方案',
        //   bannerCon: '晓慧XMUI AI产品让学校教育智慧，让师生教学简便',
        //   bannerBgUrl: solution8_banner,
        //   componentName: 'XiaohuiAIOT',
        //   component: XiaohuiAIOT,
        // },
        {
          title: '教师发展解决方案',
          bannerCon:
            '为教师提供专业的OMO培训服务、全体系实践型的培训资源和全链条实用性的信息化工具',
          bannerBgUrl: solution9_banner,
          componentName: 'TeacherDevelopment',
          component: TeacherDevelopment,
        },
        {
          title: '课后服务解决方案',
          bannerCon:
            '将信息化作为课后服务质量提升内生变量，为区域教育局、学校、机构提供差异化课后服务、运营',
          bannerBgUrl: solution10_banner,
          componentName: 'AfterClassService',
          component: AfterClassService,
        },
        {
          title: '智笔课堂互动解决方案',
          bannerCon:
            '基于纸笔书写的课堂互动教学产品和智笔课堂云平台，助力师生实现数字课堂的平滑过渡',
          bannerBgUrl: solution11_banner,
          componentName: 'IntelligentPenClassroom',
          component: IntelligentPenClassroom,
        },
        {
          title: '学业质量管理解决方案',
          bannerCon:
            '基于大数据的精准诊断与学情分析，构建以教学学情反馈为中心的评价体系，全面提升师生教学质量',
          bannerBgUrl: solution12_banner,
          componentName: 'AcademicQuality',
          component: AcademicQuality,
        },
      ],
      activeNav: 0,
      swiperList: [
        // {
        //   url: '图片路径',
        //   title: '图片描述',
        // },
      ], // 轮播图片
      swiperOption: {
        // autoplay: {
        //   disableOnInteraction: false, // 用户操作后自动轮播时间重新开始计时
        //   delay: 5000, // 自动轮播切换时间
        // },
        autoplay: false,
        loop: true, // 开启循环
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  methods: {
    toggleNav(index, item) {
      this.activeNav = index
      this.$refs.swiper.swiper.slideTo(this.activeNav + 1, 500)
      this.$router.push({
        path: '/products-plans/solution',
        query: { name: item.componentName },
      })
    },
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
}
</script>

<style scoped lang="scss">
%solution {
  .solution-product {
    background: #f7f7f8;
  }
  #swiper {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .swiper {
      @include px2vw('height', 750);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      & > .swiper-pagination {
        display: none;
        width: auto !important;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0;
        ::v-deep span {
          padding: 0 20px;
          margin: 0 4px;
          border-radius: 4px;
        }
        ::v-deep .swiper-pagination-bullet-active {
          background-color: $--color-white;
        }
      }
    }
    .swiper_content {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      max-width: 1374px;
      transform: translate(-50%, -50%);
      text-align: center;
      color: $--color-white;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 2;
      h1 {
        @include px2vw('font-size', 56);
      }
      p {
        @include px2vw('font-size', 32);
        line-height: 40px;
      }
    }
  }
  .navigation {
    width: 90%;
    max-width: 1320px;
    margin: 80px auto 50px;
    padding-top: 38px;
    padding-bottom: 8px;
    @include px2vw('padding-left', 30);
    @include px2vw('padding-right', 30);
    box-sizing: border-box;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
    & ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        width: max-content;
        // min-width: 120px;
        box-sizing: border-box;
        display: flex;
        // margin: 0 6px;
        margin-bottom: 30px;
        cursor: pointer;
        span {
          padding: 6px 10px;
          margin: 0 10px;
        }
        span.active-nav {
          background-color: $--color-primary;
          color: $--color-white;
        }
      }
      li:hover {
        span {
          background-color: $--color-primary;
          color: $--color-white;
        }
      }
      li::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: #999999;
        // margin-left: 10px;
      }
      li:last-child()::after {
        content: '';
        display: none;
      }
    }
  }
}
.view-pc {
  @extend %solution;
  @media screen and (max-width: 1200px) {
    .navigation {
      width: 90%;
      padding-top: 24px;
      ul {
        li {
          margin-bottom: 15px;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
  .navigation ul li span {
    // width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
}
// mobile会将px转成vw
.view-mobile {
  @extend %solution;
  .solution-product {
    margin-top: 44px;
    #swiper {
      .swiper {
        height: 200px;
        & > .swiper-pagination {
          bottom: 10px;
          ::v-deep span {
            padding: 0 10px;
            margin: 0 4px;
            border-radius: 4px;
          }
          ::v-deep .swiper-pagination-bullet-active {
            background-color: $--color-white;
          }
        }
      }
      .swiper_content {
        h1 {
          font-size: 22px;
          margin: 10px auto 0;
        }
        .sp {
          font-size: 20px;
        }
        p {
          font-size: 12px;
          line-height: 20px;
          margin: 10px 20px;
        }
      }
    }
    .navigation {
      margin: 0 auto 40px;
      padding-left: 0;
      padding-right: 0;
      & ul {
        width: 100%;
        display: flex;
        li {
          min-width: unset;
          max-width: unset;
          margin: 0 0 10px;
          padding: 0 6px;
          text-align: center;
          border-right: 1px #999999 solid;
          &::after {
            margin-left: 0;
            background-color: transparent;
          }
          &:last-child {
            border-right: 0;
          }
          span {
            padding: 6px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
