<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3>学业质量管理解决方案</h3>
        <!-- <p class="title_en">Smart Study Travel Management Solution</p> -->
      </div>
      <div class="solution_content">
        <p>
          晓羊策学大数据精准学情分析系统依托于人工智能、OCR识别、评价统分分析引整、大数据等先进技术。通过各种考试、每日作业数据采集，构建以教学学情反馈为中心的评价体系，基于大数据的精准诊断与学情分析，为各学科教研提供教学报告支撑、助教师打造精准教学应用场景、为班级共性问题提供解决方案、为学生个性化学情提供提升方案等，最终打造教学、作业/考试、评价一体化，通过学情反馈教学质量，通过反馈的学情来优化课堂构建，全面提升师生的教学质量。
        </p>
        <img src="@/assets/images/solutionProduct/AcademicQuality/pic1.png" />
        <img src="@/assets/images/solutionProduct/AcademicQuality/pic2.png" />
      </div>
    </div>
    <div class="campus_box">
      <div class="campus">
        <div class="title">
          <h3 class="pc">网络阅卷（服务于K12【小初高阶段】）</h3>
          <h3 class="mobile">
            网络阅卷 <br />
            <span> （服务于K12【小初高阶段】） </span>
          </h3>
          <!-- <p class="title_en">Intelligent Base Management System</p> -->
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/AcademicQuality/pic3.png" />
        </div>
      </div>
      <div class="campus pt0">
        <div class="title">
          <h3 class="pc">个性化错题本（服务于10～12年级【高中阶段】）</h3>
          <h3 class="mobile">
            个性化错题本 <br />
            <span>（服务于10～12年级【高中阶段】）</span>
          </h3>
          <!-- <p class="title_en">Intelligent Base Management System</p> -->
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/AcademicQuality/pic4.png" />
        </div>
      </div>
      <div class="campus pt0">
        <div class="title">
          <h3 class="pc">错题巩固练（服务于9～12年级【初三+高中阶段】）</h3>
          <h3 class="mobile">
            错题巩固练 <br />
            <span>（服务于9～12年级【初三+高中阶段】）</span>
          </h3>
          <!-- <p class="title_en">Courses And Activiti</p> -->
        </div>
        <div class="campus_content third">
          <img src="@/assets/images/solutionProduct/AcademicQuality/pic5.png" />
        </div>
      </div>
      <div class="campus pt0">
        <div class="title">
          <h3 class="pc">控量提质作业（服务于7～9年级【初中阶段】）</h3>
          <h3 class="mobile">
            控量提质作业 <br />
            <span>（服务于7～9年级【初中阶段】）</span>
          </h3>
          <!-- <p class="title_en">Courses And Activiti</p> -->
        </div>
        <div class="campus_content">
          <img
            class="last"
            src="@/assets/images/solutionProduct/AcademicQuality/pic6.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto 30px;
      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          display: block;
          margin: 40px auto;
          &:nth-child(2) {
            width: 100%;
          }
          &:nth-child(3) {
            width: 55%;
            margin-top: 100px;
          }
        }
      }
    }
    .campus_box {
      background: $--color-white;
      .campus {
        text-align: center;
        padding-top: 40px;
        img {
          // padding-bottom: 40px;
          display: block;
          margin: 0px auto 40px;
          width: 55%;
        }
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 40px !important;
      .title {
        margin: 0 auto 40px;
      }
      .pc {
        display: block;
      }
      .mobile {
        display: none;
      }
      &_content {
        width: 90% !important;
        max-width: 1320px !important;
        margin: 0 auto;
      }
      img {
        width: 100% !important;
        margin-bottom: 0 !important;
      }
      .last {
        margin-bottom: 100px !important;
      }
    }
  }
  .content {
    .campus_box {
      .third {
        // margin-top: -30px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        p {
          font-size: 18px;
        }
      }
      .campus_box {
        .campus {
          &_content {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
.view-mobile {
  @extend %solution;
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      // margin-bottom: 15px !important;
      line-height: 30px !important;
      // margin: 40px 0;
      margin: 20px 0 15px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      padding-top: 0 !important;
      p {
        font-size: 14px;
        line-height: 28px;
      }
      img {
        &:nth-child(2) {
          width: 100%;
        }
        &:nth-child(3) {
          width: 100%;
          margin-top: 30px;
        }
      }
    }
    .campus_box {
      .pt0 {
        padding-top: 0 !important;
      }
      .campus {
        &:last-child {
          margin-bottom: 30px;
        }
        .pc {
          display: none;
        }
        .mobile {
          display: block;
          margin-top: 30px !important;
          span {
            font-size: 18px;
          }
        }
        padding: 20px 15px 0;
        img {
          width: 100%;
          margin: 20px 0 20px;
        }
      }
    }
  }
}
</style>
