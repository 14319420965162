<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3>绿色校园整体解决方案</h3>
        <!-- <p class="title_en">Smart Campus Energy-Green Management Solution</p> -->
      </div>
      <div class="solution_content">
        <p>
          绿色校园整体解决方案以物联网大数据技术为基石，以物联管控、能耗分析为重点抓手，旨在解决学校及相关部门“管理有麻烦”、“能源有浪费”、“安全有隐患”三大痛点，构建“低成本、高效率、精细化、全覆盖”的绿色校园
          。
        </p>
        <img src="@/assets/images/solutionProduct/new_solution5.png" alt="" />
      </div>
    </div>
    <div class="campus pt20">
      <div class="title">
        <h3>整体结构</h3>
      </div>
      <div class="campus_content">
        <img src="@/assets/images/solutionProduct/solution5_item2_pc1.png" />
      </div>
    </div>
    <div class="campus">
      <div class="title">
        <h3>系统平台</h3>
      </div>
      <div class="campus_content">
        <img src="@/assets/images/solutionProduct/solution5_item2_pc2.png" />
      </div>
    </div>
    <div class="campus">
      <div class="title">
        <h3>核心硬件</h3>
      </div>
      <div class="campus_content">
        <img src="@/assets/images/solutionProduct/solution5_item2_pc3.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto 30px;
      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          width: 100%;
          display: block;
          margin: 40px auto;
        }
      }
    }
    .campus {
      background: $--color-white;
      text-align: center;
      padding-top: 40px;
      &:last-child {
        margin-bottom: 40px;
      }
      img {
        // padding-bottom: 40px;
        display: block;
        margin: 60px auto 0;
        width: 50%;
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 40px;
      &_content {
        width: 90% !important;
        width: 1320px;
        margin: 0 auto;
      }
      img {
        width: 100%;
        margin: 40px auto 0;
      }
      &:nth-child(2) {
        img {
          margin-top: 40px;
        }
      }
      &:last-child {
        img {
          margin: 40px auto 100px;
        }
      }
    }
  }
  .campus_content {
    width: 72%;
    max-width: 1320px;
    margin: 0 auto;
    img {
      width: 100% !important;
      // margin: 60px auto 0 !important;
    }
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        p {
          font-size: 18px;
        }
      }
      .campus {
        &_content {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
        img {
          width: 90%;
        }
      }
    }
  }
}
.view-mobile {
  @extend %solution;
  .campus_content {
    margin-top: 20px;
  }
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      line-height: 30px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      // padding-top: 40px;
      p {
        font-size: 14px;
        line-height: 28px;
      }
    }
    .campus {
      padding: 0 15px;
      &:last-child {
        margin-bottom: 30px;
      }
      &_content {
        margin-top: 0;
      }
      .title {
        padding-top: 30px;
      }
      img {
        width: 100%;
        padding: 20px 0 0;
        margin: 0;
      }
    }
    .pt20 {
      // padding-top: 40px;
    }
  }
}
</style>
