<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3>教师发展解决方案</h3>
        <!-- <p class="title_en">Teacher Development Solution</p> -->
      </div>
      <div class="solution_content">
        <p>
          教师是人类灵魂的工程师，是人类文明的传承者，承载着传播知识、传播思想、传播真理，塑造灵魂、塑造生命、塑造新人的时代重任。教师发展解决方案是以赋能教师终身成长为建设目标，应用信息化技术，为教师提供专业的OMO培训服务（教师培训）、全体系实践型的培训资源（晓羊学苑）和全链条实用性的信息化工具（教师发展平台）。
        </p>
        <img src="@/assets/images/solutionProduct/new_solution_67.png" alt="" />
        <img src="@/assets/images/solutionProduct/new_solution9.png" alt="" />
      </div>
    </div>
    <div class="campus">
      <div class="title">
        <h3>教师培训</h3>
        <!-- <p class="title_en">Teacher training</p> -->
      </div>
      <div class="campus_content">
        <p>
          晓羊可以为学校或区域教育局提供各级各类的师训服务、自主研发类服务、教育咨询类服务和区域整体解决类服务。OMO贯穿浸润整个培训服务过程，真正发挥线上和线下融合从而提升培训效果的作用。
        </p>
        <div>
          <div>
            <img
              src="@/assets/images/solutionProduct/solution9_item2_pc1.png"
            />
          </div>
          <div>
            <img
              src="@/assets/images/solutionProduct/solution9_item2_pc2.png"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="campus">
      <div class="title">
        <h3>晓羊学苑</h3>
        <!-- <p class="title_en">Xiaoyang Academy</p> -->
      </div>
      <div class="campus_content">
        <p>
          晓羊学苑是基于学校品牌建设和教师专业发展需要，提供优质的实践性教师学习资源和OMO特色教师培训服务为一体的专业网络平台。该平台是以解决教师教育教学中存在的实际问题为核心目标，做最贴近一线教师需求的学习资源，真正做到学完即实践、实践即反思、反思即成长。
        </p>
        <img src="@/assets/images/solutionProduct/solution9_item2_pc3.png" />
      </div>
    </div>

    <div class="campus">
      <div class="title">
        <h3>学校教师发展平台</h3>
        <!-- <p class="title_en">School Teacher Development Platform</p> -->
      </div>
      <div class="campus_content">
        <p>
          晓羊学校教师发展平台，是晓羊集团为学校量身定做的教师专业化成长综合性云平台。平台紧扣国家政策，以教师的实际需求为核心，协助学校建立具有完全自主知识产权的实用、高效、便捷的教师成长网络空间，提升教师培训的工作质量，让教师成长看得见，让培训成果可量化。
        </p>
        <img
          class="last"
          src="@/assets/images/solutionProduct/solution9_item2_pc4.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto 30px;
      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          width: 100%;
          display: block;
          margin: 40px auto;
        }
      }
    }
    .campus {
      background: $--color-white;
      padding: 40px 0 0;

      .campus_content {
        width: 70%;
        max-width: 1320px;
        margin: 0 auto;
        padding-bottom: 20px;
        p {
          font-size: 24px;
          line-height: 1.5em;
          margin: 50px 0;
        }
        & > div {
          display: flex;
          justify-content: space-around;
          > div {
            width: 40%;
            img {
              width: 100%;
            }
          }
        }
      }
      &:nth-child(3) {
        .campus_content {
          img {
            width: 30%;
            display: block;
            margin: 20px auto;
          }
        }
      }
      &:nth-child(4) {
        .campus_content {
          img {
            width: 100%;
            display: block;
            margin: 20px auto;
          }
        }
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 40px;

      &_content {
        width: 90% !important;
        max-width: 1320px !important;
        margin: 0 auto;
        padding-bottom: 0 !important;
        p {
          line-height: 32px !important;
          font-size: 18px !important;
          text-align: justify !important;
          color: #666 !important;
          margin: 25px 0 30px !important;
        }
      }
      img {
        width: 100%;
        margin: 0 auto !important;
      }
      .last {
        margin-bottom: 100px !important;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        margin: 0 auto;
        padding-top: 0px;
        p {
          font-size: 18px;
        }
        img {
          width: 100%;
        }
      }
      .campus {
        padding: 20px 0;
        .campus_content {
          width: 90% !important;
          margin-left: auto;
          margin-right: auto;
          p {
            font-size: 20px;
            margin: 30px 0;
          }
        }
      }
    }
  }
}
.view-mobile {
  @extend %solution;
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      line-height: 30px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      // padding-top: 40px;
      p {
        font-size: 14px;
        line-height: 28px;
      }
      img {
        width: 100%;
      }
    }
    .campus {
      padding: 40px 0 0;
      .campus_content {
        width: 90%;
        p {
          font-size: 14px;
          line-height: 28px;
          color: #666;
          margin: 30px 0;
        }
        & > div {
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
          img {
            width: 90%;
            display: block;
            margin: 20px auto;
          }
        }
      }
      &:nth-child(3) {
        .campus_content {
          img {
            width: 50%;
          }
        }
      }
      &:nth-child(4) {
        .campus_content {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
