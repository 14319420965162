<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3>智笔课堂互动解决方案</h3>
        <!-- <p class="title_en">Smart Study Travel Management Solution</p> -->
      </div>
      <div class="solution_content">
        <p>
          智慧课学互动系统是—套遵循传统教学，基于纸笔书写的课堂互动教学产品。既遵从数师和学生传统的纸笔书写习惯和学习方式，又与数字课堂平滑过渡。教师脱离黑板和电脑的束缚，讲课更加自在，学生在任何普通纸张轻松书写，笔记可以在教师端即时展现。板书、笔记自动上传到电脑，同步到智笔课堂云平台，多维度统计分析课堂互动数据，用户可多终端查看。智笔课堂云平台为教师提供课前备课、课堂回顾、课后作业、学情分析、师生交流互动的全流程业务服务，为家长提供学生的学习数据，学业表现，助力孩子学业成长。
        </p>
        <img src="@/assets/images/solutionProduct/IntelligentPen.jpg" />
        <img src="@/assets/images/solutionProduct/IntelligentPen1.png" />
      </div>
    </div>
    <div class="campus_box">
      <div class="campus">
        <div class="title">
          <h3>四大核心应用场景</h3>
          <!-- <p class="title_en">Intelligent Base Management System</p> -->
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/IntelligentPen2.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 40px;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto 30px;
      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          display: block;
          margin: 40px auto;
          &:nth-child(2) {
            width: 100%;
          }
          &:nth-child(3) {
            width: 90%;
            margin-top: 40px;
          }
        }
      }
    }
    .campus_box {
      background: $--color-white;
      .campus {
        text-align: center;
        padding-top: 40px;

        img {
          // padding-bottom: 40px;
          display: block;
          margin: 0px auto 40px;
          width: 55%;
        }
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 1px !important;
      .title {
        h3 {
          margin: 50px auto !important;
        }
      }
      &_content {
        width: 90% !important;
        max-width: 1320px !important;
        margin: 0 auto;
      }
      img {
        width: 100% !important;
        margin-bottom: 10px !important;
      }
    }
  }
  .content .solution_content img:nth-child(3) {
    margin-top: 80px;
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        p {
          font-size: 18px;
        }
      }
      .campus_box {
        .campus {
          img {
            width: 90%;
          }
        }
      }
    }
  }
}
.view-mobile {
  @extend %solution;
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      margin-bottom: 15px !important;
      line-height: 30px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      padding-top: 0 !important;
      p {
        font-size: 14px;
        line-height: 28px;
      }
      img {
        &:nth-child(2) {
          width: 100%;
        }
        &:nth-child(3) {
          width: 100%;
        }
      }
    }
    .campus_box {
      .pt0 {
        padding-top: 0 !important;
      }
      .campus {
        padding: 10px 15px 0;
        img {
          width: 100%;
          margin: 30px 0 20px;
        }
      }
    }
  }
}
</style>
