<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3>平安校园整体解决方案</h3>
        <!-- <p class="title_en">
          Integrated Campus Campus Safety Management Solution
        </p> -->
      </div>
      <div class="solution_content">
        <p>
          智校安平安校园整体解决方案，综合使用[大数据+云计算+AI+物联+移动应用]等多种技术组合，赋能和提升校园安全工作。主要产品形态包括：区域局校一体化平台/单校平台两种。在校园安全管理领域，我们首倡“一套班子+一个平台+一套制度”的三合一服务模式。平台的价值目标：让学校真正用起来，把校园安全管理工作做细、做透；让教育局的管理和服务职能更加高效、落地、智能化。平台围绕[数据+工作流+功能场景]三位一体驱动，让教育局、学校的安全管理工作流紧密结合，充分闭环，无缝融合。在前端应用场景方面，平台亦规划了丰富的智能AI交互云屏解决方案，将教育局的工作管理需求和学校的场景需求进行应用和数据的无缝融合，集关键数据实时呈现和交互功能为一体。
        </p>
        <img src="@/assets/images/solutionProduct/new_solution6.png" alt="" />
        <img src="@/assets/images/solutionProduct/solution6_item2_pc1.png" />
      </div>
    </div>
    <div class="campus">
      <div class="campus_content">
        <img src="@/assets/images/solutionProduct/solution6_item2_pc2.png" />
      </div>
      <p style="margin-bottom: 60px">
        学校应用场景：在学校，通过在校门、围墙、食堂、宿舍、操场、体育场馆、教学区、办公区及各类重点区域（天台、小树林、厕所、水泵房、配电房、实验室等）的学生行为管理，匹配安全模型，分析安全异常和隐患，精准处置。<br />
        教育局应用场景：区域校园安全管理平台采集下属学校各项安全隐患及异常行为数据，从人防、物防、技防、心防、联防及应急处突等多维度进行大数据分析，整体把控区域学校安全态势，复盘制定针对性强的安全管理方案，提升区域安全管理水平，促进区域校园的安全、稳定、可持续发展。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto 30px;
      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          display: block;
          margin: 40px auto;
          &:nth-child(2) {
            width: 100%;
          }
          &:nth-child(3) {
            width: 80%;
            margin-top: 40px;
          }
        }
      }
    }
    .campus {
      background: $--color-white;
      text-align: center;
      padding-top: 40px;
      p {
        line-height: 32px;
        font-size: 18px;
        text-align: justify;
        color: #666;
        // letter-spacing: 0.2em;
        margin: 25px 0;
        padding: 0 5%;
      }

      img {
        // padding-bottom: 40px;
        display: block;
        margin: 60px auto 40px;
        width: 100%;
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 40px;
      &_content {
        width: 90% !important;
        max-width: 1320px !important;
        margin: 0 auto;
      }
      img {
        width: 100%;
        margin: 20px auto 50px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        p {
          font-size: 18px;
        }
      }
      .campus {
        &_content {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
        img {
          width: 70%;
        }
      }
    }
  }
}
.view-mobile {
  @extend %solution;
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      line-height: 30px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      // padding-top: 40px;
      p {
        font-size: 14px;
        line-height: 28px;
      }
    }
    .campus {
      padding: 30px 15px 0;
      img {
        width: 100%;
        margin: 20px 0;
      }
    }
  }
}
</style>
