<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3 class="pc">“五育并举”评价整体解决方案（小学版）</h3>
        <h3 class="mobile">“五育并举”评价整体解决方案<br />（小学版）</h3>
        <!-- <p class="title_en">
          Integrated Solution For Well-Rounded Student Assessment（Elementary
          School Version）
        </p> -->
      </div>
      <div class="solution_content">
        <p>
          基于国家对中小学学生全面成长发展要求，围绕德、智、体、美、劳五个方面，面向各场景全过程量化学生数据，为学生老师家长形成看得见的成长。积极探索从而达成学校的育人目标。
        </p>
        <img src="@/assets/images/solutionProduct/new_solution3.png" alt="" />
      </div>
    </div>
    <div class="campus">
      <div class="title">
        <h3>智慧教育学生评价新生态</h3>
        <!-- <p class="title_en">Wisdom Education Student Evaluation New Ecology</p> -->
      </div>
      <div class="campus_content">
        <div class="campus_content_pic">
          <img src="@/assets/images/solutionProduct/solution3_item2_pc1.png" />
        </div>
        <div class="campus_content_pic">
          <img src="@/assets/images/solutionProduct/solution3_item2_pc2.png" />
        </div>
        <div class="campus_content_pic">
          <img src="@/assets/images/solutionProduct/solution3_item2_pc3.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto 30px;
      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          width: 100%;
          display: block;
          margin: 40px auto;
        }
      }
    }
    .campus {
      background: $--color-white;
      text-align: center;
      padding-top: 40px;

      img {
        width: 100%;
        // padding-bottom: 40px;
        display: block;
        margin: 60px auto 40px;
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .pc {
    display: inherit;
  }
  .mobile {
    display: none;
  }
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 45px;
      &_content {
        width: 90% !important;
        max-width: 1320px !important;
        margin: 0 auto;
      }
      img {
        width: 100%;
        margin: 30px auto 40px;
        &:last-child {
          margin-bottom: 100px;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        p {
          font-size: 18px;
        }
      }
      .campus {
        &_content {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
.view-mobile {
  @extend %solution;
  .pc {
    display: none;
  }
  .mobile {
    display: inherit;
  }
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      line-height: 30px !important;
    }
    .solution {
      // padding-top: 40px;
      width: 90%;
      margin: 0 auto;
      p {
        font-size: 14px;
        line-height: 28px;
      }
    }
    .campus {
      padding: 40px 0 0;
      &_content {
        // padding: 0;
        .campus_content_pic {
          padding: 30px 15px;
          &:nth-of-type(even) {
            background-color: #f7f7f8;
          }
          img {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
