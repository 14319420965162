<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3>家校社一体化劳动教育解决方案</h3>
        <!-- <p class="title_en">Smart Study Travel Management Solution</p> -->
      </div>
      <div class="solution_content">
        <p>
          晓羊集团以国家劳动教育大政方针为导向，紧跟新课标改革的步伐，以劳动实践教育教学规律为依据，应用大数据、云平台、物联网等现代信息技术手段，基于“一平台、二体系、六方案”构建区域“学校-家庭-社区（劳动实践基地等）”一体化的劳动教育环境和系统性合作机制，着力推动全社会劳动教育力量的有效整合，全面培养学生劳动素养，赋能学校上好劳动实践课，支持区域特色可持续发展的劳动教育规划。
        </p>
        <img src="@/assets/images/solutionProduct/integrated1.png" />
        <img src="@/assets/images/solutionProduct/integrated2.png" />
      </div>
    </div>
    <div class="campus_box">
      <div class="campus">
        <div class="title">
          <h3>学校</h3>
          <!-- <p class="title_en">Intelligent Base Management System</p> -->
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/integrated3.png" />
        </div>
      </div>

      <div class="campus">
        <div class="title">
          <h3>家庭（学生/家长）</h3>
          <span>
            劳动清单、成长记录、信息统计、信息同步、通知资料、家校社联评、劳动档案查询与打印
          </span>
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/integrated6.png" />
        </div>
      </div>

      <div class="campus">
        <div class="title">
          <h3>社会化劳动资源与空间</h3>
          <p>
            平台将区域范围内的社会化劳动资源、空间统一入库（例如：博物馆、非遗馆、生态园、茶艺馆、校史馆、研学基地等），并构建起特色、专业、丰富的劳动项目资源库。建立家校社三位一体的系统合作机制，实现“一区一案”“一校一案”“一家一案”及个性化劳动课/劳动周。
          </p>
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/integrated5.png" />
        </div>
      </div>

      <div class="campus">
        <div class="title">
          <h3>教育局</h3>
          <!-- <p class="title_en">Intelligent Base Management System</p> -->
        </div>
        <div class="campus_content">
          <img src="@/assets/images/solutionProduct/integrated4.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      span {
        line-height: 32px;
        font-size: 18px;
        text-align: center;
        color: #666;
        // letter-spacing: 0.2em;
        margin: 25px 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto;

      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          display: block;
          margin: 40px auto;
          &:nth-child(2) {
            width: 100%;
          }
          &:nth-child(3) {
            width: 100%;
            margin-top: 40px;
          }
        }
      }
    }
    .campus_box {
      background: $--color-white;
      .campus {
        text-align: center;
        padding-top: 100px;

        span {
          line-height: 32px;
          font-size: 18px !important;
          text-align: left;
          color: #666;
          width: 100%;
        }

        img {
          // padding-bottom: 40px;
          display: block;
          margin: 0px auto 40px;
          width: 100%;
        }
        &:last-child {
          margin-bottom: 100px;
        }
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 40px;
      &_content {
        width: 90%;
        // width: 100%;
        max-width: 1320px;
        margin: 0 auto;
      }
      img {
        width: 100% !important;
        margin: 40px auto 0 !important;
      }
    }
  }
  .content .campus_box .campus {
    width: 90%;
    margin: 0 auto;
    max-width: 1320px;
  }
  .campus_box .campus .title h3 {
    margin-bottom: 30px;
  }
  .campus_box .campus .title span {
    font-size: 20px;
  }
  .campus_box .campus .title p {
    font-size: 18px;
    text-align: justify;
    color: #666;
    line-height: 35px;
    margin: 35px 0;
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        h3 {
          font-size: 24px;
          line-height: 24px;
        }
        p {
          font-size: 12px;
        }
      }
      .campus_box {
        .campus {
          .title {
            width: 90%;
            margin: 0 auto;
            h3 {
              font-size: 24px;
              line-height: 24px;
            }
            span {
              font-size: 16px;
            }
            p {
              font-size: 12px;
            }
          }
          &_content {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
          img {
            width: 90%;
          }
        }
      }
    }
  }
}
.view-mobile {
  .campus_box .campus .title h3 {
    margin-bottom: 15px;
  }
  .campus_box .campus .title span {
    line-height: 24px;
    font-size: 15px;
  }
  .campus_box .campus .title p {
    font-size: 14px;
    text-align: justify;
    color: #666;
    line-height: 28px;
    margin-bottom: 30px;
  }
  @extend %solution;
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      line-height: 30px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      // padding-top: 40px;
      p {
        font-size: 14px;
        line-height: 28px;
      }
      img {
        &:nth-child(2) {
          width: 100%;
        }
        &:nth-child(3) {
          width: 100%;
        }
      }
    }
    .campus_box {
      .campus {
        padding: 40px 15px 0;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 30px;
        }
        img {
          width: 100%;
          margin: 20px 0 0;
        }
      }
    }
  }
}
</style>
