<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3>新高考一站式解决方案</h3>
        <!-- <p class="title_en">
          One Stop Smart Campus Solution Designed For New College Entrance
          Examination
        </p> -->
      </div>
      <div class="solution_content">
        <p>
          新高考一站式解决方案旨在为用户提供新高考一站式调研、咨询、规划和建设服务，解决用户开展新高考后面临的选、排、管、测、评、教等方面的问题。以课表数据为驱动，覆盖和打通新高考改革背景下智慧校园全业务流程，实现数据共享与业务协同；汇总和分析各业务应用数据，实现基于数据的精细化管理，支撑用户新高考改革各项工作科学、有序、可控、高效的落地。
        </p>
        <img src="@/assets/images/solutionProduct/new_solution1.png" alt="" />
      </div>
    </div>
    <div class="campus">
      <div class="title">
        <h3>整体规划架构</h3>
        <!-- <p class="title_en">Smart Campus Overall Planning Framework</p> -->
      </div>
      <div class="campus_content">
        <img :src="pc" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import mobile from '@/assets/images/solutionProduct/school_framework_mobile.png'
import pc from '@/assets/images/solutionProduct/solution1_item2_pc.png'

export default {
  data() {
    return {
      mobile: mobile,
      pc: pc,
    }
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto 30px;
      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          width: 100%;
          display: block;
          margin: 40px auto;
        }
      }
    }
    .campus {
      background: $--color-white;
      text-align: center;
      padding-top: 40px;

      img {
        width: 100%;
        // padding-bottom: 40px;
        display: block;
        margin: 60px auto 40px;
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      &_content {
        img {
          margin: 50px auto 0;
        }
      }
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 50px !important;
      }
    }
    .campus {
      padding-top: 40px;
      &_content {
        width: 90% !important;
        max-width: 1320px !important;
        // width: 100%;
        margin: 0 auto;
      }
      img {
        width: 100%;
        margin: 40px auto 100px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        h3 {
          line-height: 0 !important;
        }
        p {
          font-size: 18px;
          margin-top: 40px !important;
        }
      }
      .campus {
        &_content {
          // width: 1320px;
          width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
.view-mobile {
  @extend %solution;
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      line-height: 30px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      // padding-top: 40px;
      p {
        font-size: 14px;
        line-height: 28px;
      }
    }
    .campus {
      padding: 40px 15px 0;
      img {
        width: 100%;
        margin: 30px 0 20px;
      }
      .imgBox {
        ul {
          li {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
