<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3>晓慧XMUI AIoT平台解决方案</h3>
        <!-- <p class="title_en">Xiaohui AIoT Platform Solution</p> -->
      </div>
      <div class="solution_content">
        <p>
          XMUI系统是晓羊集团旗下基于云技术与Android系统，深度优化、定制、开发的公用智慧屏操作系统。晓慧AloT平台基于XMUI系统，融合人工智能AI技术和物联网IoT技术，为区域教育局与学校构建起一个智能化教育体系平台。平台通过搭载XMUI系统的AI设备(AI云黑板、AI一体机、AI云屏、AI学习机、AI宿舍电话、AI测温仪、AI手表/手环等)，产生并收集来自不同维度的教育教学数据，并进行人工智能分析与画像，实现学校管理设备的智联化、师生使用教学设备的数据化。基于XMUI系统的晓慧AloT，将构筑起“局与校、校与家、师与生”在不同智能终端之间、不同应用场景之间互融互通的智慧生态体系，为学校形成“AnyOne、AnyTime、AnyWhere、AnyDevice”交互的4A智慧校园。
        </p>
        <img src="@/assets/images/solutionProduct/new_solution8.png" />
        <img
          src="@/assets/images/solutionProduct/new_solution8_1.png"
          style="margin-bottom: -50px"
        />
      </div>
    </div>
    <div class="campus">
      <div class="title">
        <h3>XMUI&nbsp;&nbsp;AloT校园系统</h3>
        <!-- <p class="title_en">Product System</p> -->
      </div>
      <div class="campus_content">
        <div class="imgBox">
          <p>
            晓慧XMUI&nbsp;&nbsp;AIoT平台，支持局校多级构建人工智能物联网平台；学校可选择多种晓慧AI产品构建校园智慧物联生态。
          </p>
          <img
            src="@/assets/images/solutionProduct/solution8_item2_pc1.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="campus">
      <div class="title">
        <h3>XMUI&nbsp;&nbsp;多场景云屏</h3>
        <!-- <p class="title_en">Product Value</p> -->
      </div>
      <div class="campus_content">
        <div class="imgBox">
          <img
            src="@/assets/images/solutionProduct/solution8_item2_pc2.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="campus">
      <div class="title">
        <h3>XMUI&nbsp;&nbsp;云应用功能</h3>
        <!-- <p class="title_en">Product Value</p> -->
      </div>
      <div class="campus_content">
        <div class="imgBox">
          <img
            src="@/assets/images/solutionProduct/solution8_item2_pc3.png"
            alt=""
            class="last"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      productValueList: [
        {
          title: '教师课堂数据',
          supply: '(课堂教学时长、课件使用、互动数据）',
        },
        { title: '学生评价数据', supply: '（课堂答题、评价管理）' },
        { title: '学生测温数据', supply: '（疫情常态管控）' },
        { title: '学生运动数据', supply: '（教育部“五项”管理：体测数据）' },
        { title: '学生心率数据', supply: '（教育部“五项”管理：体质监测）' },
        { title: '学生睡眠数据', supply: '（教育部“五项”管理：睡眠管理）' },
        {
          title: '学生作业时间数据',
          supply: '（教育部“五项”管理、“双减”：作业管理）',
        },
        {
          title: '学生考勤数据',
          supply: '（“双减”：学生在校时长、课后延时服务、家长接送）',
        },
        { title: '学校设备数据', supply: '（设备管控、室场管理等）' },
      ],
    }
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto 30px;
      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          display: block;
          margin: 40px auto;
          width: 100%;
        }

        .w-img-100 {
          width: 100%;
          margin-left: 5%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 302px;
            width: auto;
          }
        }
      }
    }
    .campus {
      background: $--color-white;
      text-align: center;
      padding-top: 40px;

      img {
        width: 50%;
        padding-bottom: 40px;
        display: block;
        margin: 0 auto;
      }
      .imgBox {
        width: 100%;
        margin: 0 auto;
        p {
          font-size: 28px;
          color: #333333;
          line-height: 42px;
          width: 70%;
          margin: 20px auto;
          text-align: left;
        }
        img {
          width: 70%;
          margin-top: 30px;
        }
        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 70%;
          margin: 40px auto;
          li {
            border: 1px solid #979797;
            padding: 17px 0px;
            margin-bottom: 28px;
            width: 30%;
            span {
              display: block;
              &:nth-child(2) {
                margin-top: 10px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 40px;
      &:last-child {
        img {
          // margin-bottom: 100px !important;
          // margin-top: 0 !important;
        }
      }
      .last {
        margin-bottom: 100px !important;
      }
      &_content {
        width: 90% !important;
        max-width: 1320px;
        margin: 0 auto;
        p {
          line-height: 32px !important;
          font-size: 18px !important;
          text-align: center !important;
          color: #666 !important;
          margin: 25px 0 !important;
          width: 100% !important;
        }
        ul {
          margin-bottom: 0 !important;
          li {
            margin-bottom: 50px !important;
          }
        }
      }
      img {
        width: 100% !important;
        margin: 40px auto 0 !important;
        padding: 0 !important;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
      }
      .campus {
        &_content {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
        img {
          width: 50%;
          padding-bottom: 40px;
          display: block;
          margin: 0 auto;
        }
        .imgBox {
          width: 100%;
          margin: 0 auto;
          p {
            width: 100%;
            font-size: 22px;
          }
          img {
            width: 100%;
          }
          ul {
            width: 100%;
          }
        }
      }
    }
  }
}
.view-mobile {
  @extend %solution;
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      line-height: 30px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      // padding-top: 40px;
      p {
        font-size: 14px;
        line-height: 28px;
      }
    }
    .campus {
      &_content {
        width: 90%;
        margin: 0 auto;
        padding-bottom: 0;
        img {
          margin-top: 0;
          padding-bottom: 0;
        }
      }
      .imgBox {
        margin-bottom: 40px;
        p {
          width: 100%;
          font-size: 14px;
          line-height: 28px;
        }
        img {
          width: 100%;
        }
        ul {
          width: 100%;

          li {
            width: 80%;
            margin: 20px auto;
          }
        }
      }
    }
  }
}
</style>
