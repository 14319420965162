<template>
  <div class="content">
    <div class="solution">
      <div class="title">
        <h3 class="pc">“五育并举”评价整体解决方案（中学版）</h3>
        <h3 class="mobile">“五育并举”评价整体解决方案 <br />（中学版）</h3>
        <!-- <p class="title_en">
          Integrated Solution For Well-Rounded Student Assessment（Secondary
          School Version）
        </p> -->
      </div>
      <div class="solution_content">
        <p>
          依托《中国学生发展核心素养》，围绕核心素养5C模型，构建学生评价体系。依据学校办校理念和人文关怀一特色，定制化学生个性成长报告单、教师积分管理体系、教师专业发展体系、学校督导评价体系等。改变了传统评价方式的集中式、学期末的总结式评价习惯，评价立校以数据为驱动，重模型，重方法，重过程。
        </p>
        <img src="@/assets/images/solutionProduct/new_solution4.png" alt="" />
      </div>
    </div>
    <div class="campus">
      <div class="title">
        <h3>智慧“评价立校”新生态</h3>
        <!-- <p class="title_en">Wisdom Education Student Evaluation New Ecology</p> -->
      </div>
      <div class="campus_content">
        <div class="campus_content_pic">
          <img src="@/assets/images/solutionProduct/solution4_item2_pc1.png" />
        </div>
        <div class="campus_content_pic">
          <img src="@/assets/images/solutionProduct/solution4_item2_pc2.png" />
        </div>
        <div class="campus_content_pic">
          <img src="@/assets/images/solutionProduct/solution4_item2_pc3.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
%solution {
  .content {
    margin: 0 auto;

    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .solution {
      width: 72%;
      max-width: 1320px;
      margin: 0 auto 30px;
      padding-bottom: 20px;
      &_content {
        p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // letter-spacing: 0.2em;
          margin: 25px 0;
        }
        img {
          width: 100%;
          display: block;
          margin: 40px auto;
        }
      }
    }
    .campus {
      background: $--color-white;
      text-align: center;
      padding-top: 40px;

      img {
        // padding-bottom: 40px;
        display: block;
        margin: 60px auto 40px;
        &:nth-child(1) {
          width: 40%;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 50%;
        }
      }
    }
  }
}
.view-pc {
  @extend %solution;
  .pc {
    display: inherit;
  }
  .mobile {
    display: none;
  }
  .content {
    .solution {
      width: 90%;
      padding-bottom: 0;
      margin-bottom: 50px;
      h3 {
        line-height: 36px !important;
      }
      p {
        font-size: 18px;
        margin-top: 40px !important;
      }
    }
    .campus {
      padding-top: 40px;
      &_content {
        width: 90% !important;
        max-width: 1320px !important;
        margin: 0 auto;
      }
      img {
        width: 100% !important;
        margin: 40px auto 50px;
        &:last-child {
          margin-bottom: 100px;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .content {
      .solution {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        // width: 100%;
        // padding: 40px;
        p {
          font-size: 18px;
        }
      }
      .campus {
        &_content {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
        img {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            width: 90%;
          }
        }
      }
    }
  }
}
.view-mobile {
  @extend %solution;
  .pc {
    display: none;
  }
  .mobile {
    display: inherit;
  }
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3 {
      line-height: 30px !important;
    }
    .solution {
      width: 90%;
      margin: 0 auto;
      // padding-top: 40px;
      p {
        font-size: 14px;
        line-height: 28px;
      }
    }
    .campus {
      padding: 40px 0 0;
      &_content {
        // padding: 0;
        .campus_content_pic {
          padding: 30px 15px;
          &:nth-of-type(even) {
            background-color: #f7f7f8;
          }
          img {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
